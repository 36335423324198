import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Button from 'react-bootstrap/Button';

const NotFoundPage = () => {
  return (
    <>
      <GatsbySeo 
        title="404: Not Found | Presidium Health - Home-based Healthcare"
        description="Presidium Health provides home-based care services to patients."
        metaTags={[
          {
            name: 'keywords', 
            content: 'home-based healthcare, value-based healthcare, patient care, telemedicine'
          }
        ]}
      />

      <div className="d-flex align-items-center flex-column my-5 py-5">
        <StaticImage
          src="../../images/logo.svg"
          alt="AVneo"
          loading="eager"
          placeholder="none"
          alt="Presidium Health"
          width={181}
          className="logo"
          className="logo mb-5"
        />
        <h1 className="fw-bold">404 - Page Not Found</h1>
        <p className="mb-5">
          The page you are looking for was moved, removed, renamed or might
          never existed.
        </p>
        <Button variant="primary" as={Link} to="/">
          Back to Homepage
        </Button>
      </div>
    </>
  );
};

export default NotFoundPage;
